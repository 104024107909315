.ServicePageHeroContainer {
  background-color: #1a1e29;
  width: 98.7vw;
}
.heroText {
  color: white;
}
.leftContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Title-text {
  font-family: DM Sans;
  font-size: 3.646vw;
  font-weight: bold;
}
.ButtonCaontainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.HeroImg {
  width: 43.958vw;
}

.HeroImg1 {
  width: 43.958vw;
}

@media screen and (max-width: 786px) {
  .Title-text {
    font-size: 2.425rem;
  }
  .ServicePageHeroContainer {
    padding: 1.5rem;
  }
  .buttonContainer {
    display: flex;
    gap: 2rem;
    margin-top: 2.5vw;
  }
  .leftContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
  }
  .HeroImg1 {
    width: 100%;
  }
}
