.button{
    /* width: 14.819vw; */
    border-radius: 2.778vw;
    padding: 1.667vw 2.5vw;
}
.button img{
    margin-left: 0.556vw;
}
.gap24{
    gap:1.667vw,
}

@media screen and (max-width: 320px) {
    .button{
        height: 4rem!important;    
        width: 17rem!important;
        font-size: 1.625rem!important;
    }
  }
  
  @media screen and (min-width: 321px) and (max-width: 480px) {
    .button{
        height: 4rem!important;
        width: 17rem!important;
        font-size: 1.625rem!important;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 786px) {
    .button{
        height: 4rem!important;
        width: 17rem!important;
        font-size:1.625rem!important;
    }
  }